<template>
    <b-container>
        <b-row class="mt-4">
            <b-col>
                <h1>Organizations</h1>
            </b-col>
            <b-col class="text-right">
                <b-button v-b-toggle.sidebar-backdrop>Create new organization</b-button>
            </b-col>
        </b-row>
        <b-card>
            <b-table striped hover :items="items" :fields="fields">
                <template #cell(name)="data">
                    <router-link :to="{ name: 'EditOrganization', params: { organizationId: data.item.id } }">{{
                        data.item.name
                    }}</router-link>
                </template>
            </b-table>
        </b-card>
        <b-sidebar
            id="sidebar-backdrop"
            v-model="createVisible"
            :backdrop-variant="'dark'"
            backdrop
            shadow
            right
            @shown="showCreateOrganization"
        >
            <div class="px-3 py-2">
                <h3>Create Organization</h3>
                <b-form-group label="Name" label-for="organization-name">
                    <b-form-input id="organization-name" v-model="name" ref="focusName" type="text"></b-form-input>
                </b-form-group>
                <b-row>
                    <b-col>
                        <b-button variant="info" @click="createOrganization">{{ $t('buttons.submit') }}</b-button>
                    </b-col>
                    <b-col class="text-right">
                        <b-button variant="secondary" v-b-toggle.sidebar-backdrop>{{ $t('buttons.cancel') }}</b-button>
                    </b-col>
                </b-row>
            </div>
        </b-sidebar>
    </b-container>
</template>

<script>
import httpClient from '@/services';

export default {
    name: 'Organizations',
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    sortable: true
                }
            ],
            items: [],
            name: null,
            createVisible: false
        };
    },
    mounted() {
        this.getOrganizations();
    },
    methods: {
        getOrganizations() {
            httpClient
                .get('/organizations')
                .then(response => {
                    this.items = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        createOrganization() {
            httpClient
                .post('/organizations', { name: this.name })
                .then(() => {
                    this.name = null;
                    this.getOrganizations();
                    this.createVisible = false;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        showCreateOrganization() {
            this.$refs.focusName.focus();
        }
    }
};
</script>
