<template>
    <b-container>
        <b-row class="mt-4">
            <b-col>
                <h1>Organization</h1>
            </b-col>
        </b-row>
        <b-card v-if="organization">
            <b-row>
                <b-col>
                    <b-form-group id="input-group-1" label="Organization name:" label-for="input-1">
                        <b-form-input id="input-1" v-model="organization.name" type="text" required />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <b-form-group label="Organization Admin:">
                        <b-form-row v-for="(item, index) in admins" :key="index" class="mb-2">
                            <b-col cols="11">
                                <template v-if="item.id"> {{ item.firstName }} {{ item.lastName }} </template>
                            </b-col>
                            <b-col>
                                <b-icon-trash @click="removeAdmin(index)"></b-icon-trash>
                            </b-col>
                        </b-form-row>
                    </b-form-group>
                    <user-select :placeholder="'Add Admin'" :classes="'mr-4'" :showAll="true" @selected="addAdmin" />
                </b-col>
            </b-row>
        </b-card>
        <b-row class="mt-4">
            <b-col>
                <b-button variant="info" @click="save">{{ $t('buttons.save') }}</b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import httpClient from '@/services';
import UserSelect from '../../components/UserSelect.vue';

export default {
    name: 'EditOrganization',
    components: { UserSelect },
    data() {
        return {
            organization: null,
            admins: [],
            organizationId: null
        };
    },
    mounted() {
        const { organizationId } = this.$route.params;
        this.organizationId = organizationId;
        this.getOrganization();
        this.getOrganizationAdmins();
    },
    methods: {
        getOrganization() {
            httpClient
                .get(`/organizations/${this.organizationId}`)
                .then(response => {
                    this.organization = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getOrganizationAdmins() {
            httpClient
                .get(`/organizations/${this.organizationId}/admins`)
                .then(response => {
                    this.admins = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        addAdmin(user) {
            this.admins.push(user);
        },
        removeAdmin(index) {
            this.admins.splice(index, 1);
        },
        save() {
            httpClient
                .post(`/organizations/${this.organizationId}`, { name: this.organization.name, admins: this.admins })
                .then(() => {
                    this.$router.push({ name: 'Organizations' });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};
</script>
