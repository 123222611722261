<template>
    <vue-typeahead-bootstrap
        ref="UserSelect"
        :class="classes"
        v-model="query"
        :ieCloseFix="false"
        :data="users"
        :showAllResults="true"
        :serializer="item => item.email"
        @hit="userSelected"
        highlightClass="special-highlight-class"
        :placeholder="placeholder"
        @input="lookupUser"
    >
        <template slot="suggestion" slot-scope="{ data }">
            <div class="d-flex align-items-center">
                <span>{{ data.firstName }} {{ data.lastName }}</span>
                <span class="ml-4">{{ data.email }}</span>
            </div>
        </template>
    </vue-typeahead-bootstrap>
</template>

<script>
import httpClient from '@/services';
import { debounce } from '../utils';
export default {
    name: 'UserSelect',
    props: {
        placeholder: {
            type: String,
            default: 'Search Users'
        },
        classes: {
            type: String,
            default: ''
        },
        showAll: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            query: '',
            selectedUser: null,
            users: []
        };
    },
    methods: {
        lookupUser: debounce(function() {
            if (this.query) {
                let url = `/users?search=${this.query}`;
                if (this.showAll) {
                    url += `&skipTenant=true`;
                }
                httpClient
                    .get(url)
                    .then(response => {
                        this.users = response.data;
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        }, 500),
        userSelected(user) {
            this.$emit('selected', user);
            this.query = '';
            setTimeout(() => {
                if (this.$refs.UserSelect) {
                    this.$refs.UserSelect.$refs.input.focus();
                }
            }, 10);
        }
    }
};
</script>

<style lang="scss" scoped>
.special-input-class {
    background-color: black !important;
    color: white !important;
}
.special-highlight-class {
    font-weight: 900;
    color: #585656;
}
</style>
